.simple-row {
  padding:5px 0px;

  label {
    display:inline-block;
    min-width: 200px;
    vertical-align: top;
  }

  span {
    display:inline-block;
    font-style: italic;
  }

}