/** RESET **/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote, q {
  &:before, &:after {
    content: '';
  }
}


table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box;
}

/** RESET ENDE **/

main {
  padding: 30px;
}

body {
  background-color:#eee;
  min-height:100vh;
}

.layout {
  position:absolute;
  left:0;
  right: 0;
  top: 0;
  bottom: 0;
  display:flex;
  flex-direction: column;
}

.button__spinner {
  color:#fff;
}

* {
  font-family: 'Source Sans Pro', serif !important;
}


.section__headline {
  font-size:30px;
  margin-bottom:10px;
}

main {
  overflow:auto;
  flex-grow:1;
}

.simple-button {
  border:1px solid #ccc !important;
  border-radius:5px !important;
  padding:10px 20px !important;
  margin-left:10px !important;
}

.button {
  display: inline-block;
  background-color:#5089c8 !important;
}

.button:hover {
  background-color:#4A628D !important;
}

.upgrade-frame {
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  border:0;
  width: 100vw;
  height: 100vh;
}
