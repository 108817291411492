@import '../../scss/constants';


.sites__dashboard-button {
  position:absolute !important;
  right:10px;
  bottom:10px;
  background-color:#fff !important;
  box-shadow: $boxShadow;
}


.sites__list {
  display:flex;
  flex-wrap: wrap;
  margin-top:20px;
  padding: 10px 0 0 10px;
}

.sites__listitem {
  width:1000px;
  min-height:250px;
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: $radius;
  box-sizing:border-box;
  max-width:calc(100% - 40px);
  box-shadow: $boxShadow;

  > h2 {
    margin-bottom:10px;
    font-size:25px;
    font-weight:300;
    text-align: center;
  }

  > footer {
    text-align: right;
  }
}

.sites__base-info {
  width:60%;
  margin-right:10px;
}


/** CALL TO ACTION **/
.sites__call-to-action {
  position:relative;
  flex-grow:1;
  padding:20px;
  background: linear-gradient(to bottom, #5adf9c 0%,#40e88b 44%,#20ce76 100%);
  border-radius: $radius;
  line-height:1.5em;
  color:white;

  /*background-image:url(../../media/calltoaction.jpg);
  background-size:cover;
  background-position:center center;*/
}

.sites__call-to-action__headline {
  padding:10px 0;
  font-size:35px;
}

.sites__call-to-action__button {
  position:absolute !important;
  bottom:10px;
  right:10px;
}

.sites__screenshot {
  position: relative;
  padding-top:56.25%;
  background: linear-gradient(135deg, #7abcff 0%,#60abf8 44%,#4096ee 100%);
  border:1px solid #ccc;
  border-radius: $radius;
 /* background-image:url(../../media/placeholder.jpg);
  background-size:cover;
  background-position:center center;*/
  overflow:hidden;
}

.sites__screenshot:after {
  content:'Website';
  position:absolute;
  left:50%;
  top:50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color:white;
  font-size:50px;
  font-weight:300;
  opacity:0.5;
}

/* RIBBON TEST */
.corner-ribbon{
  position: absolute;
  width: 200px;
  top: 25px;
  right: -50px;
  transform: rotate(45deg);
  box-shadow: 0 0 3px rgba(0,0,0,.3);
  background: #e43;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: #f0f0f0;
}

/* Colors */

.corner-ribbon.white{background: #f0f0f0; color: #555;}
.corner-ribbon.black{background: #333;}
.corner-ribbon.grey{background: #999;}
.corner-ribbon.blue{background: #39d;}
.corner-ribbon.green{background: #2c7;}
.corner-ribbon.turquoise{background: #1b9;}
.corner-ribbon.purple{background: #95b;}
.corner-ribbon.red{background: #e43;}
.corner-ribbon.orange{background: #e82;}
.corner-ribbon.yellow{background: #ec0;}