@import '../../scss/constants';

.section-box {
  position:relative;
  display: inline-block;
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: $radius;
  box-sizing:border-box;
  max-width:calc(100% - 40px);
  vertical-align: top;
  box-shadow: $boxShadow;
}

.section-box.loading {
  opacity:0.5;

}

.section-box__headline {
  font-size:30px;
  margin:10px 0 20px;
}

.section-box__info {
  font-size:18px;
  margin:20px 0;
  line-height:1.3em;
  font-weight:300;
}

.section-box form > div {
  display:flex;
}

.section-box form > div:last-child {
  display:block;
  text-align:right;
  margin-top:10px;
}