.AppBar, .AppBar * {
  box-sizing: border-box;
}

.AppBar {
  min-height:65px;
  background-color:#fff;
  border-bottom: 1px solid #ccc;
  flex-grow:0;
}

.AppBar__logo {
  position: absolute;
  top: 12px;
  left: 15px;
  width: 80px;
  height: 32px;
}

.AppBar__navigation {
  position: absolute;
  right:0;
  display:flex;
}

.AppBar__navigation__item {
}

.AppBar__navigation__item .active {
  color:green;
  svg {
    fill:context-fill;
  }
}

.AppBar {
  a {
    display:block;
    padding: 10px;
    text-align: center;
    font-size:15px;
    text-decoration: none;
    color:#555;
  }

  a:hover {
    color:#ccc;
  }
  svg {
    display:block;
    width:24px;
    height: 24px;
    margin:0 auto 3px;
  }
}