.public__body {
  background-color:#cfc;
  background-image:url('../../media/page4-own-way-image-1.jpg');
  background-size:cover;
  width:100vw;
  height:100vh;
  overflow:hidden;

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.public__container {
  display:flex;
  width:90%;
  max-width:1200px;
  min-height:540px;
  margin:45vh 0 0 50vw;

  background-color:transparent;
  transform:translate(-50%, -50%);
  box-shadow: 0 1px 15px rgba(0,0,0,0.2),0 1px 6px rgba(0,0,0,0.2);
  border-radius:3px;
}

.public__info {
  width:40%;
  padding:60px 40px;
  background-color:#5089c8;
  color:#fff;
  border-radius:3px 0 0 3px;
  line-height: 1.3;
}

.public__info__headline {
  margin:0 0 50px 0;
  font-size:40px;
  font-weight:300;
  line-height:1em;
}

.public__content {
  width:60%;
  padding:65px 80px 80px;
  background-color:#fff;
  border-radius:0 3px 3px 0;
}

.public__content__headline {
  font-size:31px;
  margin-bottom:10px;
}

.public__logo {
  height:48px;
  margin-bottom:60px;
}

.public__link {
  text-transform: none !important;
}

.public__submit, .submit {
  display: inline-block;
  width:250px;
  background-color:#5089c8 !important;
}

.public__submit:hover, .submit:hover {
  background-color:#4A628D !important;
}

.public__submit {
  float: right;
}